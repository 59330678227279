<template>
  <section
    :class="{
      'mt-124': $vuetify.breakpoint.lgAndUp,
      'mt-16': $vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.lgAndUp,
      'mt-8': $vuetify.breakpoint.xsOnly,
    }"
  >
    <v-row>
      <v-col>
        <span class="h3">Zobacz na mapie!</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="mapouter">
          <div class="gmap_canvas">
            <iframe
              id="gmap_canvas"
              width="100%"
              :height="mapHeight"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Kniaziewicza%2020%20%C5%81%C3%B3d%C5%BA+(K20%20Apartamenty)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: 'LocalizationMap',
  computed: {
    mapHeight() {
      return this.$vuetify.breakpoint.lgAndUp ? '600' : '300';
    }
  }
};
</script>