<template>
  <div
    :class="`screen-loader${active ? ' active': ''}`"
  >
    <div class="v-progress-container text-center">
      <div
        class="v-progress-wrapper"
      >
        <v-progress-circular
          :size="50"
          :width="5"
          color="#1d1d4e"
          indeterminate
        />
      </div>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'ScreenLoader',
  props: {
    active: Boolean,
  },
});
</script>

<style scoped lang="scss">
@import '@/styles/colors';

.screen-loader {
  position: relative;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.54);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0s 0.3s;
  }
  .v-progress-container {
    position: absolute;
    z-index: 100002;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0s 0.3s;
    .v-progress-wrapper {
      display: flex;
      flex-direction: column;
      top: 50%;
      &:not(.sticky) {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .v-progress-circular {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &.active {
    min-height: 75px;
    &:after, > .v-progress-container {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s;
    }
  }
}
</style>
