import { render, staticRenderFns } from "./ScreenLoader.vue?vue&type=template&id=90c8cce2&scoped=true&"
import script from "./ScreenLoader.vue?vue&type=script&lang=ts&"
export * from "./ScreenLoader.vue?vue&type=script&lang=ts&"
import style0 from "./ScreenLoader.vue?vue&type=style&index=0&id=90c8cce2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90c8cce2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VProgressCircular})
