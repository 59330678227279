<template>
  <v-container
    class="mt-8 pt-16 container-bg"
    :class="{
      'pb-16': $vuetify.breakpoint.lgAndUp,
      'px-16': $vuetify.breakpoint.smAndUp,
      'pb-8': $vuetify.breakpoint.mdAndDown && !$vuetify.breakpoint.xsOnly,
      'px-4': $vuetify.breakpoint.xsOnly,
    }"
  >
    <screen-loader :active="isLoading">
      <banner
        :title="posts.title"
        :subtitle="posts.excerpt"
        :image="contactBannerImage"
      />
    </screen-loader>
    <v-row
      :class="{
        'py-16': $vuetify.breakpoint.lgAndUp,
        'pt-16 pb-0': $vuetify.breakpoint.mdAndDown && !$vuetify.breakpoint.xsOnly,
        'pt-6': $vuetify.breakpoint.xsOnly,
      }"
    >
      <screen-loader :active="isLoading">
        <v-col
          offset-lg="1"
          lg="10"
          md="12"
          sm="12"
          cols="12"
        >
          <span class="w-3 h2">{{ policyTitle }}</span>
          <div class="text-wrapper">
            <p
              v-for="(p, index) in posts.info"
              :key="index"
              class="mt-8 w-2 p2"
            >
              {{ index > 0 ? p.children.text : '' }}
            </p>
          </div>
        </v-col>
      </screen-loader>
    </v-row>
  </v-container>
</template>

<script>
import Banner from '@/components/Banner.vue';
import sanity from '../client';
import ScreenLoader from '@/components/ScreenLoader.vue';

const query = `*[slug.current == "privacy-policy"]{
  _id,
  title,
  excerpt,
  "banner": mainImage {
    asset->{
      url
    }
  },
  "info": body[0...50] {
    children[0] {
      text
    }
  },
}[0]`;
export default {
  name: 'PrivacyPolicy',
  metaInfo: {
    title: 'K20 Apartamenty',
    titleTemplate: '%s | Polityka Prywatności',
    meta: [
      { name: 'description', content: 'Polityka prywatności strony K20 Apartamenty w Łodzi.' },
    ]
  },
  components: {
    Banner,
    ScreenLoader
  },
  data() {
    return {
      posts: [],
      error: '',
      isLoading: false,
    };
  },
  computed: {
    contactBannerImage() {
      return this.posts.banner ? this.posts.banner.asset.url : '';
    },
    policyTitle() {
      return this.posts.info ? this.posts.info[0].children.text : 'Polityka Prywatności';
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.error = this.post = null;
      sanity.fetch(query).then(
        (posts) => {
          this.posts = posts;
          this.isLoading = false;
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },
};
</script>