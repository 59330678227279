<template>
  <v-app>
    <toolbar />
    <v-main class="grey lighten-4">
      <router-view />
    </v-main>
    <cookie-law
      button-text="Rozumiem!"
      theme="dark-lime"
    >
      <div slot="message">
        {{ policyText }}
        <router-link to="/privacy-policy">
          {{ link1 }}
        </router-link>
        i 
        <router-link to="/regulamin">
          {{ link2 }}
        </router-link>.
      </div>
    </cookie-law>
    <the-footer />
  </v-app>
</template>

<script>
import Toolbar from './components/Toolbar.vue';
import TheFooter from './components/Footer.vue';
import CookieLaw from 'vue-cookie-law';
import sanity from './client';

const query = `*[slug.current == "cookies-policy"]{
  _id,
  "text": body[0...10] {
    children[0...10] {
      marks,
      text
    }
  },
}[0]`;
export default {
  name: 'App',
  components: {
    Toolbar,
    CookieLaw,
    TheFooter,
  },
  data() {
    return {
      posts: [],
      error: '',
      policyText: '',
      link1: '',
      link2: '',
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.error = this.post = null;
      sanity.fetch(query).then(
        (posts) => {
          this.posts = posts;
          this.getLinksAndText();
        },
        (error) => {
          this.error = error;
        }
      );
    },
    getLinksAndText() {
      for(let arr in this.posts.text[0].children) {
        const isLink = this.posts.text[0].children[arr].marks.length > 0;
        const text = this.posts.text[0].children[arr].text;
        if(isLink && !this.link1) {
          this.link1 = text;
        } else if (isLink && this.link1) {
          this.link2 = text;
        } else if(!isLink && text.split('').length > 10) {
          this.policyText = text;
        }
      }
    }
  },
};
</script>

<style lang="scss">
@import '@/styles/global';

</style>
