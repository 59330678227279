<template>
  <screen-loader :active="isLoading">
    <section
      class="bg-white"
      :class="{
        'px-6': $vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.lgAndUp,
        'px-4': $vuetify.breakpoint.smAndDown,
      }"
    >
      <v-container
        :class="{
          'mt-8 py-8': $vuetify.breakpoint.smAndDown,
          'mt-16 py-12': $vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.lgAndUp,
          'mt-124 py-16': $vuetify.breakpoint.lgAndUp
        }"
      >
        <v-row
          :class="{
            'd-flex flex-column align-center': $vuetify.breakpoint.smAndDown,
          }"
        >
          <v-col
            lg="6"
            cols="12"
            class="d-flex justify-center flex-column"
            :class="{
              'pl-10 align-start': $vuetify.breakpoint.lgAndUp,
              'px-0': $vuetify.breakpoint.mdAndDown,
              'align-center': $vuetify.breakpoint.smAndDown,
            }"
            :style="textAlign"
          >
            <h2
              class="w-4"
              :class="{
                'mb-6': $vuetify.breakpoint.smAndUp
              }"
            >
              {{ posts.title }}
            </h2>
            <v-col
              v-if="$vuetify.breakpoint.smAndDown"
              :class="{
                'px-0': $vuetify.breakpoint.mdAndDown,
              }"
              lg="6"
              cols="12"
            >
              <v-img
                :src="aboutImage"
              />
            </v-col>
            <span
              class="mb-6 p2"
            >
              {{ posts.excerpt }}
            </span>
            <v-btn
              large
              dark
              elevation
              :block="$vuetify.breakpoint.smAndDown"
              color="grey darken-1"
              to="/about"
            >
              O nas
            </v-btn>
          </v-col>
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            :class="{
              'px-0': $vuetify.breakpoint.mdAndDown,
            }"
            lg="6"
            cols="12"
          >
            <v-img
              :src="aboutImage"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </screen-loader>
</template>

<script>
import sanity from '../../client';
import ScreenLoader from '../ScreenLoader.vue';

const query = `*[slug.current == "home-about"]{
  _id,
  title,
  "banner": mainImage {
    asset->{
      url
    }
  },
  excerpt,
}[0]`;
export default {
  name: 'About',
  components: {
    ScreenLoader,
  },
  data() {
    return {
      posts: [],
      error: '',
      isLoading: false,
    };
  },
  computed: {
    aboutImage() {
      return this.posts.banner ? this.posts.banner.asset.url : require('@/assets/banners/why-we-banner.jpg');
    },
    textAlign() {
      return `text-align: ${this.$vuetify.breakpoint.smAndUp ? '' : 'center'};`;
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.error = this.post = null;
      sanity.fetch(query).then(
        (posts) => {
          this.posts = posts;
          this.isLoading = false;
        },
        (error) => {
          this.error = error;
        }
      );
      
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/import';

.bg-white {
  background-color: $primary;
}
</style>