var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:{
    'pt-16 px-16': _vm.$vuetify.breakpoint.lgAndUp,
    'pt-8 px-0': _vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp,
  }},[_c('span',{class:_vm.titleClass},[_vm._v(_vm._s(_vm.contactTitle))]),_c('v-row',{class:_vm.wrapperPadding},[_c('contact-section',{attrs:{"border-styles":_vm.columnBorder,"icon":require('@/assets/icons/ico_phone.svg'),"information":_vm.contactPhone}}),_c('contact-section',{attrs:{"border-styles":_vm.borderRight,"icon":require('@/assets/icons/ico_email.svg'),"information":_vm.contactEmail}}),_c('contact-section',{attrs:{"border-styles":_vm.localizationBorder,"icon":require('@/assets/icons/ico_location-big.svg'),"information":_vm.contactAddress}}),(_vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp || _vm.$vuetify.breakpoint.xsOnly)?_c('v-col',{class:{
        'pl-4': !_vm.$vuetify.breakpoint.xsOnly
      },style:(_vm.borderTop),attrs:{"sm":"6","cols":"12"}},[_c('div',{staticClass:"booking",class:{
          'd-flex flex-column align-center': _vm.$vuetify.breakpoint.xsOnly
        }},[_c('v-img',{staticClass:"mb-6",attrs:{"width":"80px","src":require("@/assets/icons/ico_booking-black.svg")}}),_c('div',{class:_vm.contactInfoClass},[_c('span',{staticClass:"h3 w-3"},[_vm._v("Zarezerwuj teraz!")]),_c('br'),_c('v-btn',{staticClass:"mt-6 p1 w-3",attrs:{"text":"","large":"","outlined":"","color":"#1d1d4e","to":"/"}},[_vm._v(" Przejdź do Booking.com ")])],1)],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }