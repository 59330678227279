<template>
  <v-container
    class="pt-16 mt-8 container-bg"
    :class="{
      'px-16': $vuetify.breakpoint.smAndUp,
      'px-4': $vuetify.breakpoint.xsOnly
    }"
  >
    <screen-loader :active="isLoadingBanner">
      <banner
        :title="bannerPost.title"
        :subtitle="gallerySubtitle"
        :image="galleryBanner"
      />
    </screen-loader>
    <v-row class="py-16">
      <v-col
        lg="6"
        md="6"
        sm="6"
        cols="12"
        class="d-flex justify-center"
      >
        <v-btn
          text
          outlined
          width="100%"
          height="42px"
          class="fs-18"
          :class="activeButton('basic')"
          @click="apartmentsType('basic')"
        >
          {{ basicButtonName }}
        </v-btn>
      </v-col>
      <v-col
        lg="6"
        md="6"
        sm="6"
        cols="12"
        class="d-flex justify-center"
      >
        <v-btn
          text
          outlined
          height="42px"
          width="100%"
          class="fs-18"
          :class="activeButton('lux')"
          @click="apartmentsType('lux')"
        >
          {{ luxButtonName }}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <screen-loader :active="isLoadingDescBasic && apartments === 'basic'">
          <apartments-description
            v-if="apartments === 'basic'"
            :description-basic="basicDesc"
            :apartments="apartments"
          />
        </screen-loader>
        <screen-loader :active="isLoadingDescLux && apartments === 'lux'">
          <apartments-description
            v-if="apartments === 'lux'"
            :description-lux="luxDesc"
            :apartments="apartments"
          />
        </screen-loader>
      </v-col>
      <v-col
        class="pt-12 px-4"
        cols="12"
      >
        <span class="h2 w-3">
          Galeria zdjęć
        </span>
      </v-col>
      <gallery-list :apartments="apartments" />
    </v-row>
  </v-container>
</template>

<script>
import Banner from '@/components/Banner.vue';
import GalleryList from '@/components/gallery/GalleryList.vue';
import ApartmentsDescription from '@/components/gallery/ApartmentsDescription.vue';
import sanity from '../client';
import ScreenLoader from '@/components/ScreenLoader.vue';

const bannerQuery = `*[slug.current == "gallery-banner"]{
  _id,
  title,
  excerpt,
  "banner": mainImage {
    asset->{
      url
    }
  },
  "subtitle": body[1] {
    children[0] {
      text
    }
  },
}[0]`;

const basicApartmentsQuery = `*[slug.current == "gallery-basic-apartments"]{
  _id,
  title,
  excerpt,
  "description": body[0...100] {
    children[0] {
      text
    }
  },
}[0]`;

const luxApartmentsQuery = `*[slug.current == "gallery-lux-apartments"]{
  _id,
  title,
  excerpt,
  "description": body[0...100] {
    children[0] {
      text
    }
  },
}[0]`;

export default {
  name: 'Gallery',
  metaInfo: {
    title: 'K20 Apartamenty',
    titleTemplate: '%s | Galeria',
    meta: [
      { name: 'description', content: 'Tutaj znajdziesz wszystkie zdjęcia, oraz dokładną informację o naszych apartamentach taką jak ceny, parametry, wyposażenia itd...' },
    ]
  },
  components: {
    Banner,
    GalleryList,
    ApartmentsDescription,
    ScreenLoader,
  },
  data() {
    return {
      apartments: 'basic',
      bannerPost: [],
      basicDescPost: [],
      luxDescPost: [],
      error: '',
      isLoadingBanner: false,
      isLoadingDescBasic: false,
      isLoadingDescLux: false,
      fetchDataBasic: {},
      fetchDataLux: {},
      basicDesc: {
        titles: [],
        params: [],
        price: [],
        additionalInfo: []
      },
      luxDesc: {
        titles: [],
        params: [],
        price: [],
        additionalInfo: []
      },
    };
  },
  computed: {
    galleryBanner() {
      return this.bannerPost.banner ? this.bannerPost.banner.asset.url : require('@/assets/banners/gallery-banner.jpg');
    },
    gallerySubtitle() {
      return !this.$vuetify.breakpoint.lgAndUp && this.bannerPost.subtitle ? this.bannerPost.subtitle.children.text : this.bannerPost.excerpt;
    },
    basicButtonName() {
      return this.basicDescPost ? this.basicDescPost.title : 'Apartamenty typu "Basic"';
    },
    luxButtonName() {
      return this.luxDescPost ? this.luxDescPost.title : 'Apartamenty typu "Lux"';
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoadingBanner = true;
      this.error = this.post = null;
      sanity.fetch(bannerQuery,).then(
        (posts) => {
          this.bannerPost = posts;
          this.isLoadingBanner = false;
        },
        (error) => {
          this.error = error;
        }
      );
      this.isLoadingDescBasic = true;
      sanity.fetch(basicApartmentsQuery).then(
        (posts) => {
          this.basicDescPost = posts;
          this.fetchDataBasic = posts.description;
          this.insertDataIntoBasicArrays();
          this.isLoadingDescBasic = false;
        },
        (error) => {
          this.error = error;
        }
      );
      this.isLoadingDescLux = true;
      sanity.fetch(luxApartmentsQuery).then(
        (posts) => {
          this.luxDescPost = posts;
          this.fetchDataLux = posts.description;
          this.insertDataIntoLuxArrays();
          this.isLoadingDescLux = false;
        },
        (error) => {
          this.error = error;
        }
      );
      
    },
    apartmentsType(type) {
      this.apartments = type;
    },
    activeButton(room) {
      return this.apartments === room ? 'active' : 'inactive';
    },
    insertDataIntoBasicArrays() {
      const titlesBasic = [];
      const parametersBasic = [];
      const pricesBasic = [];
      const additionalInfoBasic = [];

      for(let item in this.fetchDataBasic) {
        if(this.fetchDataBasic[item].children.text.includes('[p]')) {
          parametersBasic.push(this.fetchDataBasic[item].children.text.replace('[p]', '').trim().split(': '));
        } else if(this.fetchDataBasic[item].children.text.includes('[c]')) {
          pricesBasic.push(this.fetchDataBasic[item].children.text.replace('[c]', '').trim());
        } else if(this.fetchDataBasic[item].children.text.includes('[d]')) {
          additionalInfoBasic.push(this.fetchDataBasic[item].children.text.replace('[d]', '').trim());
        } else if(this.fetchDataBasic[item].children.text.includes('[t]')) {
          titlesBasic.push(this.fetchDataBasic[item].children.text.replace('[t]', '').trim());
        }
      }
      this.getDataBasic(titlesBasic, parametersBasic, pricesBasic, additionalInfoBasic);
    },
    insertDataIntoLuxArrays() {
      const titlesLux = [];
      const parametersLux = [];
      const pricesLux = [];
      const additionalInfoLux = [];

      for(let item in this.fetchDataLux) {
        if(this.fetchDataLux[item].children.text.includes('[p]')) {
          parametersLux.push(this.fetchDataLux[item].children.text.replace('[p]', '').trim().split(': '));
        } else if(this.fetchDataLux[item].children.text.includes('[c]')) {
          pricesLux.push(this.fetchDataLux[item].children.text.replace('[c]', '').trim());
        } else if(this.fetchDataLux[item].children.text.includes('[d]')) {
          additionalInfoLux.push(this.fetchDataLux[item].children.text.replace('[d]', '').trim());
        } else if(this.fetchDataLux[item].children.text.includes('[t]')) {
          titlesLux.push(this.fetchDataLux[item].children.text.replace('[t]', '').trim());
        }
      }
      this.getDataLux(titlesLux, parametersLux, pricesLux, additionalInfoLux);
    },
    getDataBasic(titlesBasic, parametersBasic, pricesBasic, additionalInfoBasic) {
      for(let item of titlesBasic) {
        this.basicDesc.titles.push(item);
      }

      for(let item of parametersBasic) {
        this.basicDesc.params.push(
          {
            title: item[0],
            answer: item[1],
          }
        );
      }

      for(let item of pricesBasic) {
        this.basicDesc.price.push(
          {
            price: item,
          }
        );
      }

      for(let item of additionalInfoBasic) {
        this.basicDesc.additionalInfo.push(item);
      }
    },
    getDataLux(titlesLux, parametersLux, pricesLux, additionalInfoLux) {
      for(let item of titlesLux) {
        this.luxDesc.titles.push(item);
      }

      for(let item of parametersLux) {
        this.luxDesc.params.push(
          {
            title: item[0],
            answer: item[1],
          }
        );
      }

      for(let item of pricesLux) {
        this.luxDesc.price.push(
          {
            price: item,
          }
        );
      }

      for(let item of additionalInfoLux) {
        this.luxDesc.additionalInfo.push(item);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.active {
  background-color: rgb(230, 223, 223);
}

.fs-18 {
  font-size: 18px;
}
</style>