<template>
  <v-container
    fluid
    class="pa-0 toolbar"
  >
    <v-app-bar
      flat
      :color="toolbarBackgroundColor"
      fixed
      elevate-on-scroll
      class="h-64"
      :class="{
        'px-6': $vuetify.breakpoint.smAndUp,
        'px-4': $vuetify.breakpoint.smAndDown,
      }"
    >
      <v-toolbar-title>
        <router-link
          class="logo"
          to="/"
        >
          <v-img
            max-width="240"
            alt="K20 Apartamenty"
            :src="require('@/assets/logo.png')"
          />
        </router-link>
      </v-toolbar-title>
      <v-spacer v-if="$vuetify.breakpoint.lgAndUp" />
      <div
        v-if="$vuetify.breakpoint.lgAndUp"
        class="menu-options"
      >
        <v-btn
          plain
          class="nav-color"
          to="/"
        >
          Strona główna
        </v-btn>
        <v-btn
          plain
          class="nav-color"
          to="/galeria"
        >
          Galeria
        </v-btn>
        <v-btn
          plain
          class="nav-color"
          to="/regulamin"
        >
          Regulamin
        </v-btn>
        <v-btn
          plain
          class="nav-color"
          to="/kontakt"
        >
          Kontakt
        </v-btn>
        <v-btn
          plain
          class="nav-color"
          to="/about"
        >
          O nas
        </v-btn>
      </div>
      <v-spacer v-if="$vuetify.breakpoint.mdAndDown" />
      <hamburger
        v-if="$vuetify.breakpoint.mdAndDown"
        :is-open="menu"
        @open="openMenu"
      />
      <v-navigation-drawer
        v-if="$vuetify.breakpoint.mdAndDown"
        v-model="menu"
        app
        class="grey darken-2"
        :style="navDrawerWidth"
      >
        <div
          class="px-4 w-3 h2 white--text"
          :class="{
            'pt-12': $vuetify.breakpoint.smAndUp,
            'pt-8': $vuetify.breakpoint.xsOnly,
          }"
        >
          K20 Apartamenty
        </div>
        <v-list
          :class="{
            'py-16 mt-16': $vuetify.breakpoint.smAndUp,
            'py-16': $vuetify.breakpoint.xsOnly,
          }"
        >
          <v-list-item
            v-for="(item, index) in navigationList"
            :key="index"
            router
            :to="item.route"
          >
            <v-list-item-content class="my-1">
              <v-list-item-title>
                <span class="p2 w-2 white--text">{{ item.name }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div
          :style="socialWrapperStyles"
          class="social-wrapper d-flex align-center justify-center"
        >
          <a
            class="social"
            :style="socialLinkSize"
            href="https://www.instagram.com/k20apartamenty"
            target="_blank"
            rel="noopener"
          >
            <v-img
              src="@/assets/icons/ico_instagram-white.svg"
            />
          </a>
          <a
            class="social"
            :style="socialLinkSize"
            href="https://www.facebook.com/profile.php?id=100083952822577"
            target="_blank"
            rel="noopener"
          >
            <v-img
              src="@/assets/icons/ico_facebook-white.svg"
            />
          </a>
          <a
            class="social"
            :style="socialLinkSize"
            href="https://www.booking.com/hotel/pl/k20-apartamenty.html"
            target="_blank"
            rel="noopener"
          >
            <v-img
              src="@/assets/icons/ico_booking-white.svg"
            />
          </a>
        </div>
      </v-navigation-drawer>
    </v-app-bar>
  </v-container>
</template>

<script>
import Hamburger from '../components/Hamburger.vue';

export default {
  name: 'Toolbar',
  components: {
    Hamburger,
  },
  data() {
    return {
      scrollPosition: null,
      menu: false,
      navigationList: [
        { name: 'Strona główna', route: '/' },
        { name: 'Rezerwacja', route: '/' },
        { name: 'Galeria', route: '/galeria' },
        { name: 'Regulaminy', route: '/regulamin' },
        { name: 'Polityka Prywatności', route: '/privacy-policy' },
        { name: 'Kontakt', route: '/kontakt' },
        { name: 'O nas', route: '/about' },
      ],
    };
  },
  computed: {
    toolbarBackgroundColor() {
      return this.scrollPosition < 10 && this.$route.name === 'Home' ? 'transparent' : 'rgb(0,0,0, .9)';
    },
    navDrawerWidth() {
      return `width: ${this.$vuetify.breakpoint.smAndUp ? '55% !important' : '80% !important'};`;
    },
    socialWrapperStyles() {
      return `position: ${this.$vuetify.breakpoint.smAndUp ? 'absolute' : 'relative'}; bottom: ${this.$vuetify.breakpoint.smAndUp ? '48px' : '12px'}; left: 50%; transform: translateX(-50%)`;
    },
    socialLinkSize() {
      return `height: ${this.$vuetify.breakpoint.smAndUp ? '72px': '54px'}; width: ${this.$vuetify.breakpoint.smAndUp ? '72px': '54px'};`;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    openMenu() {
      this.menu = !this.menu;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/import';

.nav-color {
  color: $primary !important;
}

::v-deep {
  .v-toolbar__content {
    height: 64px !important;
    padding: 0;
  }
} 

.social {
  border: 2px solid;
  padding: 8px;
  margin: 0 12px;
  border-radius: 100%;
  color: #fff;
}

.toolbar {
  z-index: 1000; 
  width: 400px;
}

.h-64 {
  height: 64px !important;
}

.logo {
  text-decoration: none; 
  color: inherit;
}
</style>