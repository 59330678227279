var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen-loader',{attrs:{"active":_vm.isLoading}},[_c('v-row',{staticClass:"pb-0",class:{
      'd-flex flex-column mt-4': _vm.$vuetify.breakpoint.xsOnly,
    }},[_c('v-col',{staticClass:"py-0 px-0",attrs:{"sm":"6","cols":"12"}},[_c('v-img',{attrs:{"width":"100%","height":"100%","src":_vm.contactReserveBg}})],1),_c('v-col',{staticClass:"py-4 px-8 d-flex flex-column justify-space-between bg-g",attrs:{"sm":"6","cols":"12"}},[_c('div',{staticClass:"text"},[_c('span',{staticClass:"h2 w-3 text-color",class:{
            'mb-8': _vm.$vuetify.breakpoint.smAndUp,
            'mb-0': _vm.$vuetify.breakpoint.xsOnly,
          },style:(_vm.titleStyles)},[_vm._v(_vm._s(_vm.posts.title))]),(_vm.$vuetify.breakpoint.lgAndUp && _vm.posts.excerpt)?_c('p',{staticClass:"p2 mt-8 text-color"},[_vm._v(" "+_vm._s(_vm.posts.excerpt)+" ")]):_vm._e()]),_c('div',{staticClass:"buttons",class:{
          'd-flex flex-column align-center': _vm.$vuetify.breakpoint.xsOnly,
        }},[_c('v-btn',{staticClass:"w-3 p1 w-263",class:{
            'mt-4 mr-0': _vm.$vuetify.breakpoint.xsOnly,
            'mr-4': _vm.$vuetify.breakpoint.smAndUp,
          },attrs:{"large":"","plain":"","outlined":"","color":"white","to":"/kontakt"}},[_vm._v(" Skontaktuj się z nami ")]),_c('v-btn',{staticClass:"p1 w-3 w-263",class:_vm.$vuetify.breakpoint.mdAndDown && 'mt-2',attrs:{"large":"","elevation":"","color":"white darken-1","href":"https://www.booking.com/hotel/pl/k20-apartamenty.html","target":"_blank"}},[_vm._v(" Zarezerwuj ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }