<template>
  <v-col
    lg="4"
    sm="6"
    cols="12"
    :class="{
      'pl-4': !$vuetify.breakpoint.xsOnly
    }"
    :style="borderStyles"
  >
    <div
      class="item"
      :class="{
        'd-flex flex-column align-center': $vuetify.breakpoint.xsOnly
      }"
    >
      <v-img
        width="80px"
        class="mb-6"
        :src="icon"
      />
      <div :class="contactInfoClass">
        <span class="h3 w-3">{{ information.title }}</span><br>
        <span
          class="w-2 p3"
        >{{ information.info1 }}</span><br>
        <span
          class="w-2 p1"
        >{{ checkSecondInformation }}</span>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    borderStyles: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: '@/assets/icons/ico_phone.svg'
    },
    information: {
      type: Object,
      default: undefined,
    }
  },
  computed: {
    contactInfoClass() {
      return !this.$vuetify.breakpoint.xsOnly ? 'text' : 'text-xs';
    },
    checkSecondInformation() {
      return this.information.info2 && this.information.info2.length > 0 ? this.information.info2 : '';
    }
  }
};
</script>

<style lang="scss" scoped>
.text {
    padding-left: 16px;
}

.text-xs {
  padding-left: 0px;
  text-align: center;
}
</style>