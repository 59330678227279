<template>
  <v-container
    class="pt-16 mt-8"
    :class="{
      'px-16': $vuetify.breakpoint.smAndUp,
      'px-4': $vuetify.breakpoint.xsOnly
    }"
  >
    <v-row class="container-height">
      <v-col
        lg="7"
        cols="12"
        class="d-flex flex-column justify-center"
      >
        <h2
          :class="{
            'mb-6': $vuetify.breakpoint.lgAndUp,
            'mb-0': $vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.lgAndUp,
          }"
        >
          <span class="h1">{{ notFoundTitle }}</span> <br>
          {{ notFoundSubtitle }}
        </h2>
        <span class="p2">
          {{ textBeforeLink }}
          <router-link
            class="w-3 link-c"
            to="/"
          >
            {{ link }}
          </router-link> {{ textAfterLink }}
        </span>
      </v-col>
      <v-col
        lg="5"
        cols="12"
        class="d-flex align-center"
      >
        <v-img
          class="mr-auto banner-img"
          :src="notFoundImage"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sanity from '../client';

const query = `*[slug.current == "not-found"]{
  _id,
  title,
  excerpt,
  "banner": mainImage {
    asset->{
      url
    }
  },
  body,
}[0]`;

export default {
  name: 'NotFound',
  metaInfo: {
    title: 'K20 Apartamenty',
    titleTemplate: '%s | 404',
  },
  data() {
    return {
      posts: [],
      error: '',
      textBeforeLink: '',
      textAfterLink: '',
      link: '',
    };
  },
  computed: {
    notFoundTitle() {
      return this.posts.title ? this.posts.title : 'Uuuups...';
    },
    notFoundSubtitle() {
      return this.posts.excerpt ? this.posts.excerpt : 'Nie mogę znaleźć strony której szukasz :(';
    },
    notFoundImage() {
      return this.posts.banner ? this.posts.banner.asset.url : require('@/assets/banners/not-found-banner.jpg');
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.error = this.post = null;
      sanity.fetch(query).then(
        (posts) => {
          this.posts = posts;
          this.getTextAndLink();
        },
        (error) => {
          this.error = error;
        }
      );
    },
    getTextAndLink() {
      this.textBeforeLink = this.posts.body[0].children[0].text;
      this.link = this.posts.body[0].children[1].text;
      this.textAfterLink = this.posts.body[0].children[2].text;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/import';

.link-c {
  color: $secondary;
}

.banner-img {
  border-radius: 12px;
  max-height: 700px;
}

.container-height {
  max-height: 1000px;
}
</style>