<template>
  <viewer
    :options="options"
    :images="imageUrls"
  >
    <img
      v-for="(url, index) in imageUrls"
      :key="index"
      :src="url"
      :class="imageSize"
      class="px-2 py-2 c-pointer"
    >
  </viewer>
</template>

<script>
export default {
  name: 'ApartmentsInformations',
  props: {
    imageUrls: {
      type: Array,
      default: undefined,
    }
  },
  data() {
    return {
      options: {
        'button': true, 
        'navbar': true, 
        'title': false, 
        'toolbar': true, 
        'tooltip': false, 
        'movable': false, 
        'zoomable': true, 
        'rotatable': false, 
        'scalable': false, 
        'transition': true, 
        'fullscreen': false, 
        'keyboard': true,
      },
    };  
  },
  computed: {
    imageSize() {
      let imgClass;
      if(this.$vuetify.breakpoint.xsOnly) {
        imgClass = 'xs-only';
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        imgClass = 'lg-up';
      } else {
        imgClass = 'md-only';
      }
      return imgClass;
    }
  }
};
</script>

<style lang="scss" scoped>
.c-pointer {
    cursor: pointer;
}

.xs-only {
    height: 215px;
    width: 100%
}

.lg-up {
    height: 180px; 
    width: 25%
}

.md-only {
    height: 215px; 
    width: 50%
}
</style>