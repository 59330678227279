<template>
  <v-container fluid>
    <v-col
      v-for="(item, index) in additionalInfo"
      :key="index"
      cols="12"
    >
      <span class="w-3 p2">
        {{ item }}
      </span>
      <br><br>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: 'ApartmentsInformations',
  props: {
    additionalInfo: {
      type: Array,
      default: undefined,
    }
  }
};
</script>