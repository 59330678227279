<template>
  <screen-loader :active="isLoading">
    <v-container
      fluid
      class="pa-0 banner d-flex align-center"
      :style="bannerImage"
    >
      <v-container
        class="banner-container"
        :class="{
          'mx-15': $vuetify.breakpoint.lgAndUp,
        }"
      >
        <v-row>
          <v-col
            cols="12"
            :class="{
              'px-6': $vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.lgAndUp,
              'px-4 d-flex flex-column align-center': $vuetify.breakpoint.xsOnly
            }"
            :style="xsTextAlign"
          >
            <h1
              class="banner-title w-2 l-2"
              :class="{
                'f-size': $vuetify.breakpoint.smAndUp,
                'h1': $vuetify.breakpoint.xsOnly,
              }"
            >
              {{ bannerTitle }}
            </h1>
            <div
              class="divider"
            />
            <span
              class="banner-subtitle h3"
              :style="smUpTextAlign"
            >{{ bannerSubtitle }}</span>
          </v-col>
        </v-row>
        <v-row
          :class="{
            'date-wrapper': $vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.lgAndUp,
            'mt-16': $vuetify.breakpoint.smAndDown,
          }"
        >
          <v-col
            cols="12"
            class="d-flex justify-center"
            :class="{
              'px-6': $vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.lgAndUp,
              'px-4': $vuetify.breakpoint.smAndDown,
            }"
          >
            <form 
              id="hotresChooser" 
              action="https://panel.hotres.pl/v4_step1"
              target="_blank" 
              class="hotresChooser" 
              method="get"
            >
              <input
                id="hotresOid"
                type="hidden"
                name="oid"
                value="3349"
              >
              <input
                id="hotresLang"
                type="hidden"
                name="lang"
                value=""
              >
              <input
                id="hotresArrival"
                type="hidden"
                name="arrival"
              >
              <input
                id="hotresDeparture"
                type="hidden"
                name="departure"
              >
              <div
                id="hotresRangeChooser"
                class="hotresRangeChooser"
              >
                <div id="hotresArrivalWrap" />
                <div id="hotresDepartureWrap" />
              </div>
              <div
                id="hotresAdultsChooser"
                class="hotresAdultsChooser"
              >
                <select
                  id="hotresAdultsSelect"
                  name="adults"
                />
              </div>
              <div class="hotresSubmit">
                <button
                  id="hotresButtonChooser"
                  dark
                  outlined
                  class="search-button"
                />
              </div>
            </form>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </screen-loader>
</template>

<script>
import sanity from '../../client';
import imageUrlBuilder from '@sanity/image-url';
import ScreenLoader from '../../components/ScreenLoader.vue';

const imageBuilder = imageUrlBuilder(sanity);
const query = `*[slug.current == "home-banner"]{
  _id,
  title,
  "banner": mainImage {
    asset->{
      url
    }
  },
  excerpt,
}[0]`;

export default {
  name: 'HomeBanner',
  components: {
    ScreenLoader,
  },
  data() {
    return {
      posts: [],
      error: '',
      isLoading: false,
    };
  },
  computed: {
    bannerImage() {
      return `background-image: url(${this.posts.banner ? this.imageUrlFor(this.posts.banner) : require('@/assets/banners/home-banner.jpg')});`;
    },
    bannerTitle() {
      return this.posts.title ? this.posts.title : 'Mieszkania do wynajęcia w Łodzi';
    },
    bannerSubtitle() {
      return this.posts.excerpt ? this.posts.excerpt : 'Komfortowe apartamenty dla Ciebie i Twoich bliskich';
    },
    xsTextAlign() {
      return `text-align: ${this.$vuetify.breakpoint.xsOnly ? 'center' : ''}`;
    },
    smUpTextAlign() {
      return `text-align: ${this.$vuetify.breakpoint.smAndUp ? '' : 'center'};`;
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://panel.hotres.pl/public/api/hotres_v4_chooser.js';
    script.async = true;
    script.onload = () => {
      // eslint-disable-next-line no-undef
      new hotresChooser({'maxAdults':10});
    };
    document.body.appendChild(script);
  },
  methods: {
    imageUrlFor(source) {
      return imageBuilder.image(source);
    },
    fetchData() {
      this.isLoading = true;
      this.error = this.post = null;
      sanity.fetch(query).then(
        (posts) => {
          this.posts = posts;
          this.isLoading = false;
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/import';

.banner-container {
  max-width: 1785px;
}

.banner {
    position: relative;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    background-color: #575454; 
    background-blend-mode: overlay; 
}

.divider {
    margin: 25px 0;
    height: 4px;
    width: 50%;
    background-color: $primary !important;
}

.banner-title {
  color: $primary;
  max-width: 1300px;
}

.f-size {
  font-size: 50px;
}

.banner-subtitle {
  color: $primary;
}

.search-button {
  font-size: 24px;
  font-weight: 600;
}

.date-wrapper {
  margin-top: 140px;
}
</style>