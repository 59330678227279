<template>
  <v-container
    fluid
    class="price-wrapper py-0"
  >
    <v-row
      v-for="(price, index) in prices"
      :key="index"
      class="py-2"
      :class="{
        'px-6': $vuetify.breakpoint.smAndUp,
        'px-4': $vuetify.breakpoint.xsOnly,
      }"
    >
      <span class="w-3 p2">
        {{ price.price }}
      </span>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ApartmentsPrices',
  props: {
    prices: {
      type: Array,
      default: undefined,
    }
  }
};
</script>

<style lang="scss" scoped>
.price-wrapper {
  display: flex; 
  flex-wrap: wrap;
  align-items: flex-end;
}
</style>