<template>
  <screen-loader :active="isLoading">
    <v-row
      class="pb-0"
      :class="{
        'd-flex flex-column mt-4': $vuetify.breakpoint.xsOnly,
      }"
    >
      <v-col
        class="py-0 px-0"
        sm="6"
        cols="12"
      >
        <v-img
          width="100%"
          height="100%"
          :src="contactReserveBg"
        />
      </v-col>
      <v-col
        sm="6"
        cols="12"
        class="py-4 px-8 d-flex flex-column justify-space-between bg-g"
      >
        <div
          class="text"
        >
          <span
            :style="titleStyles"
            class="h2 w-3 text-color"
            :class="{
              'mb-8': $vuetify.breakpoint.smAndUp,
              'mb-0': $vuetify.breakpoint.xsOnly,
            }"
          >{{ posts.title }}</span>
          <p
            v-if="$vuetify.breakpoint.lgAndUp && posts.excerpt"
            class="p2 mt-8 text-color"
          >
            {{ posts.excerpt }}
          </p>
        </div>
          
        <div
          :class="{
            'd-flex flex-column align-center': $vuetify.breakpoint.xsOnly,
          }"
          class="buttons"
        >
          <v-btn
            large
            plain
            outlined
            color="white"
            class="w-3 p1 w-263"
            :class="{
              'mt-4 mr-0': $vuetify.breakpoint.xsOnly,
              'mr-4': $vuetify.breakpoint.smAndUp,
            }"
            to="/kontakt"
          >
            Skontaktuj się z nami
          </v-btn>
          <v-btn
            large
            elevation
            :class="$vuetify.breakpoint.mdAndDown && 'mt-2'"
            class="p1 w-3 w-263"
            color="white darken-1"
            href="https://www.booking.com/hotel/pl/k20-apartamenty.html"
            target="_blank"
          >
            Zarezerwuj
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </screen-loader>
</template>

<script>
import sanity from '../../client';
import ScreenLoader from '@/components/ScreenLoader.vue';

const query = `*[slug.current == "about-contact-reserve"]{
  _id,
  title,
  excerpt,
  "banner": mainImage {
    asset->{
      url
    }
  },
}[0]`;
export default {
  name: 'ContactReserve',
  components: {
    ScreenLoader,
  },
  data() {
    return {
      posts: [],
      error: '',
      isLoading: false,
    };
  },
  computed: {
    contactReserveBg() {
      return this.posts.banner ? this.posts.banner.asset.url : require('@/assets/banners/propose-banner.jpg');
    },
    titleStyles() {
      return `display: ${this.$vuetify.breakpoint.xsOnly ? 'block' : ''}; text-align: ${this.$vuetify.breakpoint.xsOnly ? 'center' : ''};`;
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.error = this.post = null;
      sanity.fetch(query).then(
        (posts) => {
          this.posts = posts;
          this.isLoading = false;
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/import';

.text-color {
  color: $primary !important;
}

.bg-g {
  background-color: #808080;
}

.w-263 {
   width: 263px;
}
</style>