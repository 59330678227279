var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen-loader',{attrs:{"active":_vm.isLoading}},[_c('v-container',{staticClass:" pa-0 d-flex justify-center",attrs:{"fluid":""}},[_c('div',{staticClass:"gallery-card",class:{
        'mt-8 mx-4': _vm.$vuetify.breakpoint.smAndDown,
        'mt-16 mx-6': _vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp,
        'mt-124 pt-2': _vm.$vuetify.breakpoint.lgAndUp
      },style:(_vm.cardSize)},[_c('v-row',{staticClass:"px-4"},[_c('v-col',{staticClass:"d-flex align-start justify-center flex-column",attrs:{"cols":"12"}},[_c('span',{staticClass:"w-4 mb-0",class:{
              'h2': _vm.$vuetify.breakpoint.smAndUp,
              'h3 pt-2': _vm.$vuetify.breakpoint.xsOnly,
            }},[_vm._v(" "+_vm._s(_vm.posts.title)+" ")])])],1),_c('v-row',{staticClass:"py-0",style:(_vm.imageWrapper)},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"p-r"},[_c('div',{staticClass:"collage-wrapper",class:_vm.wrapperHeight},_vm._l((_vm.images),function(image,index){return _c('v-img',{key:index,staticClass:"img",attrs:{"src":image}})}),1)])])],1),_c('v-row',{staticClass:"mt-16 pt-10"},[_c('v-col',{staticClass:"mt-124",attrs:{"offset":"1","offset-md":"2","md":"8","cols":"10"}},[_c('v-btn',{attrs:{"large":"","dark":"","block":"","elevation":"","color":"grey darken-1","to":"/galeria"}},[_vm._v(" Sprawdź ")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }