<template>
  <v-container
    fluid
    class="pa-0"
  >
    <screen-loader :active="isLoading">
      <v-container
        class="pt-16 mt-8 container-bg"
        :class="{
          'px-16': $vuetify.breakpoint.smAndUp,
          'px-4': $vuetify.breakpoint.xsOnly
        }"
      >
        <banner
          :title="posts.title"
          :subtitle="posts.excerpt"
          :image="aboutBannerImage"
        />

        <v-row
          :class="{
            'py-16': $vuetify.breakpoint.lgAndUp,
            'py-8': $vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.xsOnly,
          }"
        >
          <v-col
            offset-lg="1"
            lg="10"
            md="12"
            sm="12"
            cols="12"
            class="d-flex align-center flex-column"
          >
            <span
              :style="headerStyles"
              class="h2 w-3 mb-8"
            >{{ aboutTitle }}</span>

            <v-divider class="divider" />

            <div class="text-wrapper mt-8 mb-8">
              <p
                v-for="(p, index) in posts.info"
                :key="index"
                class="p2"
              >
                {{ index > 0 ? p.children.text : '' }}
              </p>
            </div>

            <v-divider class="divider" />
          </v-col>
        </v-row>
      </v-container>
    </screen-loader>
    <v-container
      class="pb-3 contact-reserve-bg"
    >
      <contact-reserve />
    </v-container>
  </v-container>
</template>

<script>
import Banner from '@/components/Banner.vue';
import ContactReserve from '@/components/about/ContactReserve.vue';
import sanity from '../client';
import ScreenLoader from '@/components/ScreenLoader.vue';

const query = `*[slug.current == "about-info"]{
  _id,
  title,
  excerpt,
  "banner": mainImage {
    asset->{
      url
    }
  },
  "info": body[0...50] {
    children[0] {
      text
    }
  },
}[0]`;
export default {
  name: 'About',
  metaInfo: {
    title: 'K20 Apartamenty',
    titleTemplate: '%s | O nas',
    meta: [
      { name: 'description', content: 'Zapoznaj się z naszą działalnością bliżej. K20 Apartamenty - to coś więcej niż zwykłe wynajęcie apartamentów!' },
    ]
  },
  components: {
    Banner,
    ContactReserve,
    ScreenLoader
  },
  data() {
    return {
      posts: [],
      error: '',
      isLoading: false,
    };
  },
  computed: {
    aboutBannerImage() {
      return this.posts.banner ? this.posts.banner.asset.url : require('@/assets/banners/about-banner.jpg');
    },
    headerStyles() {
      return `display: ${this.$vuetify.breakpoint.xsOnly ? 'block' : ''}; text-align: ${this.$vuetify.breakpoint.xsOnly ? 'center' : ''};`;
    },
    aboutTitle() {
      return this.posts.info ? this.posts.info[0].children.text : 'Zapoznaj się z naszą historią!';
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.error = this.post = null;
      sanity.fetch(query).then(
        (posts) => {
          this.posts = posts;
          this.isLoading = false;
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.contact-reserve-bg {
  max-width: 1300px; 
  background-color: white;
}

.divider {
  border-width: 1px; 
  width: 40%; 
  border-color: grey;
}
</style>