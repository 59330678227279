var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 toolbar",attrs:{"fluid":""}},[_c('v-app-bar',{staticClass:"h-64",class:{
      'px-6': _vm.$vuetify.breakpoint.smAndUp,
      'px-4': _vm.$vuetify.breakpoint.smAndDown,
    },attrs:{"flat":"","color":_vm.toolbarBackgroundColor,"fixed":"","elevate-on-scroll":""}},[_c('v-toolbar-title',[_c('router-link',{staticClass:"logo",attrs:{"to":"/"}},[_c('v-img',{attrs:{"max-width":"240","alt":"K20 Apartamenty","src":require('@/assets/logo.png')}})],1)],1),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-spacer'):_vm._e(),(_vm.$vuetify.breakpoint.lgAndUp)?_c('div',{staticClass:"menu-options"},[_c('v-btn',{staticClass:"nav-color",attrs:{"plain":"","to":"/"}},[_vm._v(" Strona główna ")]),_c('v-btn',{staticClass:"nav-color",attrs:{"plain":"","to":"/galeria"}},[_vm._v(" Galeria ")]),_c('v-btn',{staticClass:"nav-color",attrs:{"plain":"","to":"/regulamin"}},[_vm._v(" Regulamin ")]),_c('v-btn',{staticClass:"nav-color",attrs:{"plain":"","to":"/kontakt"}},[_vm._v(" Kontakt ")]),_c('v-btn',{staticClass:"nav-color",attrs:{"plain":"","to":"/about"}},[_vm._v(" O nas ")])],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-spacer'):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown)?_c('hamburger',{attrs:{"is-open":_vm.menu},on:{"open":_vm.openMenu}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-navigation-drawer',{staticClass:"grey darken-2",style:(_vm.navDrawerWidth),attrs:{"app":""},model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('div',{staticClass:"px-4 w-3 h2 white--text",class:{
          'pt-12': _vm.$vuetify.breakpoint.smAndUp,
          'pt-8': _vm.$vuetify.breakpoint.xsOnly,
        }},[_vm._v(" K20 Apartamenty ")]),_c('v-list',{class:{
          'py-16 mt-16': _vm.$vuetify.breakpoint.smAndUp,
          'py-16': _vm.$vuetify.breakpoint.xsOnly,
        }},_vm._l((_vm.navigationList),function(item,index){return _c('v-list-item',{key:index,attrs:{"router":"","to":item.route}},[_c('v-list-item-content',{staticClass:"my-1"},[_c('v-list-item-title',[_c('span',{staticClass:"p2 w-2 white--text"},[_vm._v(_vm._s(item.name))])])],1)],1)}),1),_c('div',{staticClass:"social-wrapper d-flex align-center justify-center",style:(_vm.socialWrapperStyles)},[_c('a',{staticClass:"social",style:(_vm.socialLinkSize),attrs:{"href":"https://www.instagram.com/k20apartamenty","target":"_blank","rel":"noopener"}},[_c('v-img',{attrs:{"src":require("@/assets/icons/ico_instagram-white.svg")}})],1),_c('a',{staticClass:"social",style:(_vm.socialLinkSize),attrs:{"href":"https://www.facebook.com/profile.php?id=100083952822577","target":"_blank","rel":"noopener"}},[_c('v-img',{attrs:{"src":require("@/assets/icons/ico_facebook-white.svg")}})],1),_c('a',{staticClass:"social",style:(_vm.socialLinkSize),attrs:{"href":"https://www.booking.com/hotel/pl/k20-apartamenty.html","target":"_blank","rel":"noopener"}},[_c('v-img',{attrs:{"src":require("@/assets/icons/ico_booking-white.svg")}})],1)])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }