<template>
  <screen-loader :active="isLoading">
    <v-container
      class="pt-16 mt-8 container-bg"
      :class="{
        'px-16 pb-16': $vuetify.breakpoint.smAndUp,
        'pb-8': $vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.lgAndUp,
        'px-4 pb-0': $vuetify.breakpoint.xsOnly,
      }"
    >
      <banner
        :title="posts.title"
        :subtitle="posts.excerpt"
        :image="contactBannerImage"
      />
      <contact-information
        :contact-info="mainInformations"
        :class="{
          'mt-6': $vuetify.breakpoint.xsOnly,
        }"
      />
      <localization-map />
    </v-container>
  </screen-loader>
</template>

<script>
import Banner from '@/components/Banner.vue';
import ContactInformation from '@/components/contact/ContactInformation.vue';
import LocalizationMap from '@/components/contact/LocalizationMap.vue';
import sanity from '../client';
import ScreenLoader from '@/components/ScreenLoader.vue';

const query = `*[slug.current == "contact-info"]{
  _id,
  title,
  excerpt,
  "banner": mainImage {
    asset->{
      url
    }
  },
  "info": body[0...20] {
    children[0] {
      text
    }
  },
}[0]`;

export default {
  name: 'Contact',
  metaInfo: {
    title: 'K20 Apartamenty',
    titleTemplate: '%s | Kontakt',
    meta: [
      { name: 'description', content: 'Skontaktuj się z nami tak jak Ci wygodniej: drogą e-mailową, telefonicznie, lub po prostu przyjdź do nas i uzyskaj wszelkie interesujące informację dotyczące wynajęcia apartamentów w Łodzi.' },
    ]
  },
  components: {
    Banner,
    ContactInformation,
    LocalizationMap,
    ScreenLoader
  },
  data() {
    return {
      posts: [],
      error: '',
      mainInformations: [],
      isLoading: false,
    };
  },
  computed: {
    contactBannerImage() {
      return this.posts.banner ? this.posts.banner.asset.url : require('@/assets/banners/contact-banner.jpg');
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.error = this.post = null;
      sanity.fetch(query).then(
        (posts) => {
          this.posts = posts;
          this.sortInArrays();
          this.isLoading = false;
        },
        (error) => {
          this.error = error;
        }
      );
    },
    sortInArrays() {
      const contactTitle = [];
      const contactPhone = [];
      const contactEmail = [];
      const contactAddress = [];

      for(let item in this.posts.info) {
        switch(item) {
        case '0':
          contactTitle.push({title: this.posts.info[item].children.text});
          break;
        case '1':
          contactPhone.push({
            title: this.posts.info[item].children.text,
            info1: this.posts.info[parseInt(item)+1].children.text,
            info2: this.posts.info[parseInt(item)+2].children.text,
          });
          break;
        case '4':
          contactEmail.push({
            title: this.posts.info[item].children.text,
            info1: this.posts.info[parseInt(item)+1].children.text,
            info2: this.posts.info[parseInt(item)+2].children.text,
          });
          break;
        case '7':
          contactAddress.push({
            title: this.posts.info[item].children.text,
            info1: this.posts.info[parseInt(item)+1].children.text,
            info2: this.posts.info[parseInt(item)+2].children.text,
          });
          break;
        default:
          break;
        }
      }
      this.mainInformations.push({mainTitle: contactTitle[0]});
      this.mainInformations.push({phoneInfo: contactPhone[0]});
      this.mainInformations.push({emailInfo: contactEmail[0]});
      this.mainInformations.push({addressInfo: contactAddress[0]});

    }
  },
};
</script>