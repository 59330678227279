<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-container
      class="pt-16 mt-8 container-transparent"
      :class="{
        'px-16': $vuetify.breakpoint.smAndUp,
        'px-4': $vuetify.breakpoint.xsOnly
      }"
      :fluid="$vuetify.breakpoint.xsOnly"
    >
      <screen-loader :active="isLoadingBanner">
        <banner
          :title="posts.title"
          :subtitle="posts.excerpt"
          :image="documentsBanner"
        />
      </screen-loader>
      <v-row
        :class="{
          'py-16': $vuetify.breakpoint.lgAndUp,
          'py-8': $vuetify.breakpoint.mdAndDown,
        }"
      >
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <span
            class="h2 w-3"
            :class="{
              'mb-6': $vuetify.breakpoint.lgAndUp,
              'mb-0': $vuetify.breakpoint.mdAndDown,
            }"
          >Lista dokumentów</span>
        </v-col>
        <v-col
          offset-lg="2"
          lg="8"
          md="12"
          cols="12"
        >
          <p class="w-3">
            Pliki do pobrania
          </p>
          <screen-loader :active="isLoadingRegulations">
            <documents-list
              v-for="(document, index) in documents"
              :key="index"
              :item="document"
              @download="downloadPdf(index)"
            />
          </screen-loader>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import sanity from '../client';
import { jsPDF } from 'jspdf';
import font from '@/fonts/base64/montserrat-regular';
import Banner from '@/components/Banner.vue';
import DocumentsList from '@/components/documents/DocumentsList.vue';
import ScreenLoader from '@/components/ScreenLoader.vue';

const query = `*[slug.current == "regulations-info"]{
  _id,
  title,
  excerpt,
  "banner": mainImage {
    asset->{
      url
    }
  },
}[0]`;

const documentsQuery = `*[slug.current == $slug]{
  _id,
  title,
  "content": body[0...50] {
    children[0] {
      text
    }
  },
}[0...20]`;
export default {
  name: 'Documents',
  metaInfo: {
    title: 'K20 Apartamenty',
    titleTemplate: '%s | Regulaminy',
    meta: [
      { name: 'description', content: 'Wszystkie niezbędne dokumenty w jednym miejscu! Regulaminy wynajęcia apartmentów, oddania, i inne.' },
    ]
  },
  components: {
    Banner,
    DocumentsList,
    ScreenLoader
  },
  data() {
    return {
      posts: [],
      error: '',
      isLoadingBanner: false,
      isLoadingRegulations: false,
      documentsPost: [],
      documents: [],
    };
  },
  computed: {
    documentsBanner() {
      return this.posts.banner ? this.posts.banner.asset.url : require('@/assets/banners/docs-banner.jpg');
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoadingBanner = true;
      this.error = this.post = null;
      sanity.fetch(query).then(
        (posts) => {
          this.posts = posts;
          this.isLoadingBanner = false;
        },
        (error) => {
          this.error = error;
        }
      );
      for(let i=1; i < 50; i++) {
        this.isLoadingRegulations = true;
        sanity.fetch(documentsQuery, {slug: 'regulamin-' + i}).then(
          (posts) => {
            this.documentsPost = posts;
            if(this.documentsPost[0]) {
              this.documents.push(this.documentsPost[0]);
              this.isLoadingRegulations = false;
            }
          },
          (error) => {
            this.error = error;
          }
        );
      }
    },
    downloadPdf(index) {
      const doc = new jsPDF();
      const pageHeight= doc.internal.pageSize.getHeight();

      doc.addFileToVFS('Montserrat-Regular.tff', font);
      doc.addFont('Montserrat-Regular.tff', 'Montserrat-Regular', 'normal');
      doc.setFont('Montserrat-Regular', 'normal');

      let text = [];
      let y = 20;

      for(let p in this.documents[index].content) {
        text.push(this.documents[index].content[p].children.text);
        text.push('\n');
      }
      var bodyContent = doc.splitTextToSize(text, 175);

      for (var i = 0; i < bodyContent.length; i++) {
        if (y+10 > pageHeight) {
          y = 20;
          doc.addPage();
        }
        doc.text(20, y, bodyContent[i], {lineHeightFactor: 1.3});
        y += 8;
      }   

      doc.output('dataurlnewwindow', this.documents[index].title);
      doc.save(this.documents[index].title);
    }
  },
};
</script>

<style lang="scss" scoped>
.container-transparent {
  max-width: 1300px; 
  background-color: transparent;
}
</style>