var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen-loader',{attrs:{"active":_vm.isLoading}},[_c('v-container',{staticClass:"pa-0 banner d-flex align-center",style:(_vm.bannerImage),attrs:{"fluid":""}},[_c('v-container',{staticClass:"banner-container",class:{
        'mx-15': _vm.$vuetify.breakpoint.lgAndUp,
      }},[_c('v-row',[_c('v-col',{class:{
            'px-6': _vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp,
            'px-4 d-flex flex-column align-center': _vm.$vuetify.breakpoint.xsOnly
          },style:(_vm.xsTextAlign),attrs:{"cols":"12"}},[_c('h1',{staticClass:"banner-title w-2 l-2",class:{
              'f-size': _vm.$vuetify.breakpoint.smAndUp,
              'h1': _vm.$vuetify.breakpoint.xsOnly,
            }},[_vm._v(" "+_vm._s(_vm.bannerTitle)+" ")]),_c('div',{staticClass:"divider"}),_c('span',{staticClass:"banner-subtitle h3",style:(_vm.smUpTextAlign)},[_vm._v(_vm._s(_vm.bannerSubtitle))])])],1),_c('v-row',{class:{
          'date-wrapper': _vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp || _vm.$vuetify.breakpoint.lgAndUp,
          'mt-16': _vm.$vuetify.breakpoint.smAndDown,
        }},[_c('v-col',{staticClass:"d-flex justify-center",class:{
            'px-6': _vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp,
            'px-4': _vm.$vuetify.breakpoint.smAndDown,
          },attrs:{"cols":"12"}},[_c('form',{staticClass:"hotresChooser",attrs:{"id":"hotresChooser","action":"https://panel.hotres.pl/v4_step1","target":"_blank","method":"get"}},[_c('input',{attrs:{"id":"hotresOid","type":"hidden","name":"oid","value":"3349"}}),_c('input',{attrs:{"id":"hotresLang","type":"hidden","name":"lang","value":""}}),_c('input',{attrs:{"id":"hotresArrival","type":"hidden","name":"arrival"}}),_c('input',{attrs:{"id":"hotresDeparture","type":"hidden","name":"departure"}}),_c('div',{staticClass:"hotresRangeChooser",attrs:{"id":"hotresRangeChooser"}},[_c('div',{attrs:{"id":"hotresArrivalWrap"}}),_c('div',{attrs:{"id":"hotresDepartureWrap"}})]),_c('div',{staticClass:"hotresAdultsChooser",attrs:{"id":"hotresAdultsChooser"}},[_c('select',{attrs:{"id":"hotresAdultsSelect","name":"adults"}})]),_c('div',{staticClass:"hotresSubmit"},[_c('button',{staticClass:"search-button",attrs:{"id":"hotresButtonChooser","dark":"","outlined":""}})])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }