<template>
  <v-container fluid>
    <v-row
      v-for="(parameter, index) in parameters"
      :key="index"
      class="px-2"
    >
      <v-col
        lg="4"
        md="4"
        cols="12"
      >
        <span class="w-2 p2">
          {{ parameter.title }}:
        </span>
      </v-col>
      <v-col
        lg="8"
        md="8"
        cols="12"
        :class="$vuetify.breakpoint.xsOnly && 'pt-0'"
      >
        <span class="w-3 p2">
          {{ parameter.answer }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ApartmentsParameters',
  props: {
    parameters: {
      type: Array,
      default: undefined,
    }
  }
};
</script>