<template>
  <div
    class="hamburger"
    :class="isOpen ? 'open' : ''"
    @click="openMenu"
  >
    <span />
    <span />
    <span />
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['open'],
  methods: {
    openMenu() {
      this.$emit('open');
    }
  }
};
</script>

<style lang="scss" scoped>
.hamburger {
    height: 45px;
    width: 60px;
    position: relative;
    border-radius: 15px;
    span {
        height: 4px;
        width: 40px;
        display: block;
        background: #000;
        position: absolute;
        background: #fff;
        left: 18px;
        transition: all 0.3s ease-in-out;
        &:nth-child(1) {
            top: 10px;
        }
        &:nth-child(2) {
            top: 20px;
        }
        &:nth-child(3) {
            top: 30px;
        }
    }
    &:hover {
        cursor: pointer;
    }
}

.open {
    span {
        &:nth-child(1) {
            transform: rotate(45deg);
            top: 18px;
            left: 12px;
        }
        &:nth-child(2) {
            width: 0%;
            opacity: 0;
        }
        &:nth-child(3) {
            transform: rotate(-45deg);
            top: 18px;
            left: 11px;
        }
    }
}

</style>