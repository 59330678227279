<template>
  <v-container
    fluid
    :class="{
      'pt-16 pb-8': $vuetify.breakpoint.lgAndUp,
      'py-8 px-6': $vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.lgAndUp,
      'py-6 px-1': $vuetify.breakpoint.xsOnly,
    }"
    class="footer"
  >
    <v-container class="footer-container">
      <v-row
        :class="{
          'd-flex flex-column': $vuetify.breakpoint.xsOnly,
        }"
      >
        <v-col
          sm="4"
          cols="8"
        >
          <p class="w-3 p2 title">
            K20 Apartamenty
          </p>
          <router-link to="/about">
            <p class="p1 w-2">
              O nas
            </p>
          </router-link>
          <router-link to="/">
            <p class="p1 w-2">
              Booking
            </p>
          </router-link>
          <router-link to="/galeria">
            <p class="p1 w-2">
              Galeria
            </p>
          </router-link>
          <router-link to="/regulamin">
            <p class="p1 w-2">
              Regulaminy
            </p>
          </router-link>
          <router-link to="/privacy-policy">
            <p class="p1 w-2">
              Polityka prywatności
            </p>
          </router-link>
          <router-link to="/kontakt">
            <p class="p1 w-2">
              Kontakt
            </p>
          </router-link>
        </v-col>
        <v-col
          sm="4"
          cols="8"
        >
          <p class="w-3 p2 title">
            {{ contactInfo[0] }}
          </p>
          <p class="p1 w-2">
            {{ contactInfo[1] }} <br>
            {{ contactInfo[2] }}
          </p>
          <p class="p1 w-2">
            {{ contactInfo[3] }}
          </p>
        </v-col>
        <v-col
          sm="4"
          cols="8"
        >
          <p class="w-3 p2 title">
            {{ addressInfo[0] }}
          </p>
          <p class="p1 w-2">
            {{ addressInfo[1] }} <br>
            {{ addressInfo[2] }}
          </p>
        </v-col>
      </v-row>
      <v-divider class="divider" />
      <v-row
        :class="{
          'd-flex flex-column-reverse': $vuetify.breakpoint.xsOnly
        }"
      >
        <v-col
          lg="6"
          class="d-flex align-center"
        >
          <p class="p1 w-2 mb-0">
            {{ copyrightInfo[1] }}
          </p>
        </v-col>
        <v-col
          sm="6"
          cols="12"
          :class="{
            'd-flex justify-end': $vuetify.breakpoint.smAndUp,
            'd-flex justify-space-around': $vuetify.breakpoint.xsOnly,
          }"
        >
          <a
            class="social"
            href="https://www.instagram.com/k20apartamenty"
            target="_blank"
            rel="noopener"
          >
            <v-img
              class="icon"
              src="@/assets/icons/ico_instagram.svg"
            />
          </a>
          <a
            class="social"
            href="https://www.facebook.com/profile.php?id=100083952822577"
            target="_blank"
            rel="noopener"
          >
            <v-img
              class="icon"
              src="@/assets/icons/ico_facebook.svg"
            />
          </a>
          <a
            class="social"
            href="https://www.booking.com/hotel/pl/k20-apartamenty.html"
            target="_blank"
            rel="noopener"
          >
            <v-img
              class="icon-booking"
              src="@/assets/icons/ico_booking.svg"
            />
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>


<script>
import sanity from '../client';

const query = `*[slug.current == "footer-contact"]{
  _id,
  title,
  "info": body[0...20] {
    children[0] {
      text
    }
  },
}[0]`;
export default {
  name: 'Footer',
  data() {
    return {
      posts: [],
      error: '',
      contactInfo: [],
      addressInfo: [],
      copyrightInfo: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.error = this.post = null;
      sanity.fetch(query).then(
        (posts) => {
          this.posts = posts;
          this.addInfoToArray();
        },
        (error) => {
          this.error = error;
        }
      );
    },
    addInfoToArray() {
      for(let item in this.posts.info) {
        if(item < 4) {
          this.contactInfo.push(this.posts.info[item].children.text);
        } else if(item > 3 && item < 7) {
          this.addressInfo.push(this.posts.info[item].children.text);
        } else {
          this.copyrightInfo.push(this.posts.info[item].children.text);
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/import';

.footer {
  background-color: #1a2639;
}

.footer-container {
  max-width: 1350px;
}

.icon {
  max-width: 48px !important; 
  max-height: 48px !important;
}

.icon-booking {
  max-width: 38px !important; 
  max-height: 38px !important;
}

a, .title, .p1, .p2 {
  color: $primary !important;
}

.title {
  font-family: 'Montserrat', sans-serif !important;
}

.divider {
  margin: 32px 0;
  border-color: $primary !important;
}

.social {
  height: 64px;
  width: 64px;
  padding: 8px;
  margin-left: 12px;
  border: 2px solid;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  transition: all .3s;
  &:hover {
    transform: scale(1.1);
  }
}
</style>