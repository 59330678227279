var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"footer",class:{
    'pt-16 pb-8': _vm.$vuetify.breakpoint.lgAndUp,
    'py-8 px-6': _vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp,
    'py-6 px-1': _vm.$vuetify.breakpoint.xsOnly,
  },attrs:{"fluid":""}},[_c('v-container',{staticClass:"footer-container"},[_c('v-row',{class:{
        'd-flex flex-column': _vm.$vuetify.breakpoint.xsOnly,
      }},[_c('v-col',{attrs:{"sm":"4","cols":"8"}},[_c('p',{staticClass:"w-3 p2 title"},[_vm._v(" K20 Apartamenty ")]),_c('router-link',{attrs:{"to":"/about"}},[_c('p',{staticClass:"p1 w-2"},[_vm._v(" O nas ")])]),_c('router-link',{attrs:{"to":"/"}},[_c('p',{staticClass:"p1 w-2"},[_vm._v(" Booking ")])]),_c('router-link',{attrs:{"to":"/galeria"}},[_c('p',{staticClass:"p1 w-2"},[_vm._v(" Galeria ")])]),_c('router-link',{attrs:{"to":"/regulamin"}},[_c('p',{staticClass:"p1 w-2"},[_vm._v(" Regulaminy ")])]),_c('router-link',{attrs:{"to":"/privacy-policy"}},[_c('p',{staticClass:"p1 w-2"},[_vm._v(" Polityka prywatności ")])]),_c('router-link',{attrs:{"to":"/kontakt"}},[_c('p',{staticClass:"p1 w-2"},[_vm._v(" Kontakt ")])])],1),_c('v-col',{attrs:{"sm":"4","cols":"8"}},[_c('p',{staticClass:"w-3 p2 title"},[_vm._v(" "+_vm._s(_vm.contactInfo[0])+" ")]),_c('p',{staticClass:"p1 w-2"},[_vm._v(" "+_vm._s(_vm.contactInfo[1])+" "),_c('br'),_vm._v(" "+_vm._s(_vm.contactInfo[2])+" ")]),_c('p',{staticClass:"p1 w-2"},[_vm._v(" "+_vm._s(_vm.contactInfo[3])+" ")])]),_c('v-col',{attrs:{"sm":"4","cols":"8"}},[_c('p',{staticClass:"w-3 p2 title"},[_vm._v(" "+_vm._s(_vm.addressInfo[0])+" ")]),_c('p',{staticClass:"p1 w-2"},[_vm._v(" "+_vm._s(_vm.addressInfo[1])+" "),_c('br'),_vm._v(" "+_vm._s(_vm.addressInfo[2])+" ")])])],1),_c('v-divider',{staticClass:"divider"}),_c('v-row',{class:{
        'd-flex flex-column-reverse': _vm.$vuetify.breakpoint.xsOnly
      }},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"lg":"6"}},[_c('p',{staticClass:"p1 w-2 mb-0"},[_vm._v(" "+_vm._s(_vm.copyrightInfo[1])+" ")])]),_c('v-col',{class:{
          'd-flex justify-end': _vm.$vuetify.breakpoint.smAndUp,
          'd-flex justify-space-around': _vm.$vuetify.breakpoint.xsOnly,
        },attrs:{"sm":"6","cols":"12"}},[_c('a',{staticClass:"social",attrs:{"href":"https://www.instagram.com/k20apartamenty","target":"_blank","rel":"noopener"}},[_c('v-img',{staticClass:"icon",attrs:{"src":require("@/assets/icons/ico_instagram.svg")}})],1),_c('a',{staticClass:"social",attrs:{"href":"https://www.facebook.com/profile.php?id=100083952822577","target":"_blank","rel":"noopener"}},[_c('v-img',{staticClass:"icon",attrs:{"src":require("@/assets/icons/ico_facebook.svg")}})],1),_c('a',{staticClass:"social",attrs:{"href":"https://www.booking.com/hotel/pl/k20-apartamenty.html","target":"_blank","rel":"noopener"}},[_c('v-img',{staticClass:"icon-booking",attrs:{"src":require("@/assets/icons/ico_booking.svg")}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }