<template>
  <div
    :style="containerPaddings"
    class="doc-list-container my-2"
  >
    <p
      class="item-doc"
    >
      <a
        target="_blank"
        class="d-flex align-center"
        rel="noopener"
        @click="download"
      >
        <span
          class="d-flex justify-center align-center mr-6 img-wrapper"
        >
          <img
            src="@/assets/icons/ico_file.svg"
            width="24"
            height="24"
          >
        </span>
        <span
          class="p1"
          :style="nameWidth"
        >{{ item.title }}</span>
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'DocumentsList',
  props: {
    item: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    containerPaddings() {
      return `padding: ${this.$vuetify.breakpoint.smAndUp ? '24px' : '16px'};`;
    },
    nameWidth() {
      return `width: ${this.$vuetify.breakpoint.xsOnly ? '80%' : ''};`;
    }
  },
  methods: {
    download() {
      this.$emit('download');
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/styles/import';

.doc-list-container {
  gap: 10px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 4px rgba(130, 49, 137, 0.04);
  background-color: #fff;
  width: 100%;
}

.item-doc:last-child {
  margin-bottom: 0 !important;
}

.img-wrapper{ 
  border-radius: 100%; 
  background-color: grey; 
  width: 40px !important; 
  height: 40px !important; 
  display: inline-block;
}
</style>
