<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
      >
        <screen-loader :active="isLoadingBasic && apartments === 'basic'">
          <viewer-list
            v-if="apartments === 'basic'"
            :image-urls="galleryList.basic.urls"
          />
        </screen-loader>
        <screen-loader :active="isLoadingLux && apartments === 'lux'">
          <viewer-list
            v-if="apartments === 'lux'"
            :image-urls="galleryList.lux.urls"
          />
        </screen-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';
import Vue from 'vue';
import ViewerList from '@/components/gallery/description/ViewerList.vue';
import sanity from '../../client';
import ScreenLoader from '@/components/ScreenLoader.vue';
Vue.use(VueViewer);
  
const basicImagesQuery = `*[slug.current == "gallery-basic-images"]{
  _id,
  "image": body[0...150] {
    asset->{
      url
    }
  },
}[0]`;

const luxImagesQuery = `*[slug.current == "gallery-lux-images"]{
  _id,
  "image": body[0...150] {
    asset->{
      url
    }
  },
}[0]`;

export default {
  name: 'GalleryList',
  components: {
    ViewerList,
    ScreenLoader
  },
  props: {
    apartments: {
      type: String,
      default: 'basic'
    }
  },
  data() {
    return {
      galleryList: {
        basic: {
          urls: []
        },
        lux: {
          urls: []
        },
      },
      basicImagesPost: [],
      luxImagesPost: [],
      error: '',
      isLoadingBasic: false,
      isLoadingLux: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.error = this.post = null;
      this.isLoadingBasic = true;
      sanity.fetch(basicImagesQuery,).then(
        (posts) => {
          this.basicImagesPost = posts;
          this.getUrlsFromPosts('basic');
          this.isLoadingBasic = false;
        },
        (error) => {
          this.error = error;
        }
      );
      this.isLoadingLux = true;
      sanity.fetch(luxImagesQuery,).then(
        (posts) => {
          this.luxImagesPost = posts;
          this.getUrlsFromPosts('lux');
          this.isLoadingLux = false;
        },
        (error) => {
          this.error = error;
        }
      );
    },
    getUrlsFromPosts(apartmentsType) {
      if(apartmentsType === 'basic') {
        for(let url of this.basicImagesPost.image) {
          if(url.asset) {
            this.galleryList.basic.urls.push(url.asset.url);
          }
        }
      } else if (apartmentsType === 'lux') {
        for(let url of this.luxImagesPost.image) {
          if(url.asset) {
            this.galleryList.lux.urls.push(url.asset.url);
          }
        }
      }
    }
  }
};
</script>
