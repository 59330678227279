var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-container',{staticClass:"pt-16 mt-8 container-transparent",class:{
      'px-16': _vm.$vuetify.breakpoint.smAndUp,
      'px-4': _vm.$vuetify.breakpoint.xsOnly
    },attrs:{"fluid":_vm.$vuetify.breakpoint.xsOnly}},[_c('screen-loader',{attrs:{"active":_vm.isLoadingBanner}},[_c('banner',{attrs:{"title":_vm.posts.title,"subtitle":_vm.posts.excerpt,"image":_vm.documentsBanner}})],1),_c('v-row',{class:{
        'py-16': _vm.$vuetify.breakpoint.lgAndUp,
        'py-8': _vm.$vuetify.breakpoint.mdAndDown,
      }},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"h2 w-3",class:{
            'mb-6': _vm.$vuetify.breakpoint.lgAndUp,
            'mb-0': _vm.$vuetify.breakpoint.mdAndDown,
          }},[_vm._v("Lista dokumentów")])]),_c('v-col',{attrs:{"offset-lg":"2","lg":"8","md":"12","cols":"12"}},[_c('p',{staticClass:"w-3"},[_vm._v(" Pliki do pobrania ")]),_c('screen-loader',{attrs:{"active":_vm.isLoadingRegulations}},_vm._l((_vm.documents),function(document,index){return _c('documents-list',{key:index,attrs:{"item":document},on:{"download":function($event){return _vm.downloadPdf(index)}}})}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }