var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen-loader',{attrs:{"active":_vm.isLoading}},[_c('section',{staticClass:"bg-white",class:{
      'px-6': _vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp,
      'px-4': _vm.$vuetify.breakpoint.smAndDown,
    }},[_c('v-container',{class:{
        'mt-8 py-8': _vm.$vuetify.breakpoint.smAndDown,
        'mt-16 py-12': _vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp,
        'mt-124 py-16': _vm.$vuetify.breakpoint.lgAndUp
      }},[_c('v-row',{class:{
          'd-flex flex-column align-center': _vm.$vuetify.breakpoint.smAndDown,
        }},[_c('v-col',{staticClass:"d-flex justify-center flex-column",class:{
            'pl-10 align-start': _vm.$vuetify.breakpoint.lgAndUp,
            'px-0': _vm.$vuetify.breakpoint.mdAndDown,
            'align-center': _vm.$vuetify.breakpoint.smAndDown,
          },style:(_vm.textAlign),attrs:{"lg":"6","cols":"12"}},[_c('h2',{staticClass:"w-4",class:{
              'mb-6': _vm.$vuetify.breakpoint.smAndUp
            }},[_vm._v(" "+_vm._s(_vm.posts.title)+" ")]),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-col',{class:{
              'px-0': _vm.$vuetify.breakpoint.mdAndDown,
            },attrs:{"lg":"6","cols":"12"}},[_c('v-img',{attrs:{"src":_vm.aboutImage}})],1):_vm._e(),_c('span',{staticClass:"mb-6 p2"},[_vm._v(" "+_vm._s(_vm.posts.excerpt)+" ")]),_c('v-btn',{attrs:{"large":"","dark":"","elevation":"","block":_vm.$vuetify.breakpoint.smAndDown,"color":"grey darken-1","to":"/about"}},[_vm._v(" O nas ")])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{class:{
            'px-0': _vm.$vuetify.breakpoint.mdAndDown,
          },attrs:{"lg":"6","cols":"12"}},[_c('v-img',{attrs:{"src":_vm.aboutImage}})],1):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }