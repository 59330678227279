<template>
  <screen-loader :active="isLoading">
    <v-container
      fluid
      class="pa-0"
      :class="{
        'mt-8 mb-6': $vuetify.breakpoint.smAndDown,
        'mt-16 mb-0': $vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.lgAndUp,
        'mt-124 mb-124': $vuetify.breakpoint.lgAndUp
      }"
    >
      <v-row
        class="px-0 mx-0 w-100"
        :class="{
          'd-flex flex-column-reverse': $vuetify.breakpoint.mdAndDown
        }"
      >
        <v-col
          xl="6"
          md="12"
        >
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                id="gmap_canvas"
                width="100%"
                :height="mapHeight"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Kniaziewicza%2020%20%C5%81%C3%B3d%C5%BA+(K20%20Apartamenty)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              />
            </div>
          </div>
          <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            target="_blank"
            large
            dark
            block
            elevation
            color="grey darken-1"
            class="mt-4"
            href="https://www.google.com/maps/place/Gen.+Karola+Kniaziewicza+20,+91-347+%C5%81%C3%B3d%C5%BA/@51.7995328,19.4350245,17z/data=!3m1!4b1!4m6!3m5!1s0x471bca91bef590e3:0x5da9fe20483badce!8m2!3d51.7995328!4d19.4372185!16s%2Fg%2F11c3q3t040"
          >
            Przejdź do mapy!
          </v-btn>
        </v-col>
        <v-col
          class="d-flex align-center"
          :class="{
            'px-0': $vuetify.breakpoint.mdAndDown,
            'pl-10': $vuetify.breakpoint.lgAndUp,
          }"
          xl="6"
          md="12"
        >
          <div
            :class="{
              'px-0': $vuetify.breakpoint.lgAndUp,
              'px-6 d-flex flex-column align-center': $vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.lgAndUp,
              'px-4 d-flex flex-column align-center': $vuetify.breakpoint.smAndDown,
            }"
          >
            <h2
              class="w-4"
              :class="{
                'mb-6': $vuetify.breakpoint.lgAndUp,
                'mb-0': $vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.lgAndUp,
              }"
            >
              {{ posts.title }}
            </h2>
            <v-divider
              :style="dividerWidth"
              class="divider"
            />
            <p
              :style="lgTextAlign"
              class="p2"
              :class="$vuetify.breakpoint.smAndDown && 'mb-0'"
            >
              {{ posts.excerpt }}
            </p>
            <v-btn
              v-if="$vuetify.breakpoint.mdAndUp"
              target="_blank"
              large
              dark
              :block="$vuetify.breakpoint.mdAndDown"
              elevation
              color="grey darken-1"
              href="https://www.google.com/maps/place/Gen.+Karola+Kniaziewicza+20,+91-347+%C5%81%C3%B3d%C5%BA/@51.7995328,19.4350245,17z/data=!3m1!4b1!4m6!3m5!1s0x471bca91bef590e3:0x5da9fe20483badce!8m2!3d51.7995328!4d19.4372185!16s%2Fg%2F11c3q3t040"
            >
              Przejdź do mapy!
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </screen-loader>
</template>

<script>
import sanity from '../../client';
import ScreenLoader from '../ScreenLoader.vue';

const query = `*[slug.current == "home-maps"]{
  _id,
  title,
  excerpt,
}[0]`;
export default {
  name: 'Localization',
  components: {
    ScreenLoader,
  },
  data() {
    return {
      posts: [],
      error: '',
      isLoading: false,
    };
  },
  computed: {
    mapHeight() {
      return this.$vuetify.breakpoint.lgAndUp ? '600' : '400';
    },
    dividerWidth() {
      return `width: ${this.$vuetify.breakpoint.smAndUp ? '15%' : '35%'};`;
    },
    lgTextAlign() {
      return `text-align: ${this.$vuetify.breakpoint.lgAndUp ? '' : 'center'};`;
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.error = this.post = null;
      sanity.fetch(query).then(
        (posts) => {
          this.posts = posts;
          this.isLoading = false;
        },
        (error) => {
          this.error = error;
        }
      );
      
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/import';

.mb-124 {
    margin-bottom: 124px;
}

.divider {
  border-width: 2px !important; 
  border-color: grey; 
  margin: 24px 0;
}

.w-100 {
 width: 100%;
}
</style>