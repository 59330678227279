<template>
  <screen-loader :active="isLoading">
    <v-container
      fluid
      class=" pa-0 d-flex justify-center"
    >
      <div
        :class="{
          'mt-8 mx-4': $vuetify.breakpoint.smAndDown,
          'mt-16 mx-6': $vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.lgAndUp,
          'mt-124 pt-2': $vuetify.breakpoint.lgAndUp
        }"
        class="gallery-card"
        :style="cardSize"
      >
        <v-row class="px-4">
          <v-col
            cols="12"
            class="d-flex align-start justify-center flex-column"
          >
            <span
              class="w-4 mb-0"
              :class="{
                'h2': $vuetify.breakpoint.smAndUp,
                'h3 pt-2': $vuetify.breakpoint.xsOnly,
              }"
            >
              {{ posts.title }}
            </span>
          </v-col>
        </v-row>
        <v-row
          class="py-0"
          :style="imageWrapper"
        >
          <v-col
            class="py-0"
            cols="12"
          >
            <div class="p-r">
              <div
                :class="wrapperHeight"
                class="collage-wrapper"
              >
                <v-img
                  v-for="(image, index) in images"
                  :key="index"
                  class="img"
                  :src="image"
                />
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-16 pt-10">
          <v-col
            offset="1"
            offset-md="2"
            class="mt-124"
            md="8"
            cols="10"
          >
            <v-btn
              large
              dark
              block
              elevation
              color="grey darken-1"
              to="/galeria"
            >
              Sprawdź
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </screen-loader>
</template>

<script>
import sanity from '../../client';
import ScreenLoader from '../../components/ScreenLoader.vue';

const query = `*[slug.current == "home-gallery"]{
  _id,
  title,
  "banner": body[1...4] {
    asset->{
      url
    }
  }
}[0]`;

export default {
  name: 'GalleryBanner',
  components: {
    ScreenLoader,
  },
  data() {
    return {
      images: [],
      posts: [],
      error: '',
      isLoading: false,
    };
  },
  computed: {
    wrapperHeight() {
      return this.$vuetify.breakpoint.lgAndUp ? 'h600' : 'h320';
    },
    cardSize() {
      return `width: ${this.$vuetify.breakpoint.lgAndUp ? '65%' : '100%'}; height: ${this.$vuetify.breakpoint.lgAndUp ? '770px' : '450px'};`;
    },
    imageWrapper() {
      return `height: ${this.$vuetify.breakpoint.lgAndUp ? '400px' : '100px'};`;
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    galleryBannerImages() {
      if(this.posts.banner) {
        for(let url in this.posts.banner) {
          this.images.push(this.posts.banner[url].asset.url);
        }
      }
    },
    fetchData() {
      this.isLoading = true;
      this.error = this.post = null;
      sanity.fetch(query).then(
        (posts) => {
          this.posts = posts;
          this.isLoading = false;
          this.galleryBannerImages();
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/import';
.collage-wrapper {
  display: grid;
  grid-template-columns: repeat(3,1fr);
}

.h600 {
  height: 600px;
}

.h320 {
  height: 320px;
}

.img {
  &:nth-child(0) {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1; 
    position: relative;
  }
  &:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
    position: relative;
  }
  &:nth-child(2) {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
    position: relative;
  }
}

.gallery-card {
  background-color: $primary; 
  border-radius: 20px; 
  box-shadow: 0px 8px 16px 4px rgba(130, 49, 137, 0.1);
}

.p-r {
  position: relative;
}
</style>