<template>
  <section>
    <home-banner />
    <gallery-banner />
    <about />
    <localization />
  </section>
</template>

<script>
import HomeBanner from '../components/home/HomeBanner.vue';
import GalleryBanner from '../components/home/GalleryBanner.vue';
import About from '../components/home/About.vue';
import Localization from '../components/home/Localization.vue';

export default {
  name: 'Home',
  metaInfo: {
    title: 'K20 Apartamenty',
    titleTemplate: '%s | Łódź',
    meta: [
      { name: 'description', content: 'Mieszkania do wynajęcia w Łodzi. Komfortowe apartamenty które przypadną Ci do gustu!' },
    ]
  },
  components: {
    HomeBanner,
    GalleryBanner,
    About,
    Localization,
  },
};
</script>
