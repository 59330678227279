var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"desc-shadow",class:{
    'pa-8': _vm.$vuetify.breakpoint.smAndUp,
    'pa-4': _vm.$vuetify.breakpoint.xsOnly,
  }},[_c('v-row',[_c('v-col',{class:{
        'pb-0': _vm.$vuetify.breakpoint.smAndUp,
        'px-3 pb-4': _vm.$vuetify.breakpoint.xsOnly,
      },attrs:{"cols":"12"}},[_c('span',{staticClass:"h2 w-4",class:{
          'h2': _vm.$vuetify.breakpoint.smAndUp,
          'h4': _vm.$vuetify.breakpoint.xsOnly,
        }},[_vm._v(_vm._s(_vm.parametersTitle))])]),_c('v-col',{attrs:{"cols":"12"}},[(_vm.apartments === 'basic')?_c('apartments-parameters',{attrs:{"parameters":_vm.descriptionBasic.params}}):(_vm.apartments === 'lux')?_c('apartments-parameters',{attrs:{"parameters":_vm.descriptionLux.params}}):_vm._e()],1)],1),_c('v-row',{staticClass:"pt-8"},[_c('v-col',{class:{
        'pb-0': _vm.$vuetify.breakpoint.smAndUp,
        'px-4 pb-0': _vm.$vuetify.breakpoint.xsOnly
      },attrs:{"cols":"12"}},[_c('span',{staticClass:"h2 w-4"},[_vm._v(_vm._s(_vm.priceTitle))])]),_c('v-col',{attrs:{"md":"6","cols":"12"}},[(_vm.apartments === 'basic')?_c('apartments-prices',{attrs:{"prices":_vm.descriptionBasic.price}}):(_vm.apartments === 'lux')?_c('apartments-prices',{attrs:{"prices":_vm.descriptionLux.price}}):_vm._e()],1)],1),_c('v-row',{staticClass:"pt-8 pl-2"},[(_vm.apartments === 'basic')?_c('apartments-informations',{attrs:{"additional-info":_vm.descriptionBasic.additionalInfo}}):(_vm.apartments === 'lux')?_c('apartments-informations',{attrs:{"additional-info":_vm.descriptionLux.additionalInfo}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }