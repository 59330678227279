<template>
  <v-card
    :style="bgStyles"
    class="b-shadow"
    :class="{
      'px-16': $vuetify.breakpoint.smAndUp,
      'px-4': $vuetify.breakpoint.xsOnly,
    }"
  >
    <div
      class="d-flex flex-column justify-center h-100"
    >
      <span
        :style="fontSize"
        class="w-3 c-primary"
      >
        {{ title }}
      </span>
      <span
        class="h2 w-2 c-primary"
      >
        {{ subtitle }}
      </span>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    title: {
      type: String,
      default: 'K20'
    },
    subtitle: {
      type: String,
      default: 'Apartamenty'
    },
    image: {
      type: String,
      default: ''
    }
  },
  computed: {
    bgStyles() {
      return `background: #313131; background-blend-mode: overlay; background-image: url(${this.image}); background-size: cover; background-position: center center; border-radius: 16px; height: ${this.$vuetify.breakpoint.lgAndUp ? '320px' : '260px'};`;
    },
    fontSize() {
      return `font-size: ${this.$vuetify.breakpoint.smAndUp ? '54px' : '36px'};`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/import';
.b-shadow {
    box-shadow: 0 12px 17px 2px rgb(130 49 137 / 4%), 0 5px 22px 4px rgb(130 49 137 / 4%), 0 7px 8px rgb(0 0 0 / 5%) !important;
}

.h-100 {
  height: 100%;
}

.c-primary {
  color: $primary !important;
}
</style>