var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen-loader',{attrs:{"active":_vm.isLoading}},[_c('v-container',{staticClass:"pa-0",class:{
      'mt-8 mb-6': _vm.$vuetify.breakpoint.smAndDown,
      'mt-16 mb-0': _vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp,
      'mt-124 mb-124': _vm.$vuetify.breakpoint.lgAndUp
    },attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-0 mx-0 w-100",class:{
        'd-flex flex-column-reverse': _vm.$vuetify.breakpoint.mdAndDown
      }},[_c('v-col',{attrs:{"xl":"6","md":"12"}},[_c('div',{staticClass:"mapouter"},[_c('div',{staticClass:"gmap_canvas"},[_c('iframe',{attrs:{"id":"gmap_canvas","width":"100%","height":_vm.mapHeight,"src":"https://maps.google.com/maps?width=100%25&height=600&hl=en&q=Kniaziewicza%2020%20%C5%81%C3%B3d%C5%BA+(K20%20Apartamenty)&t=&z=17&ie=UTF8&iwloc=B&output=embed","frameborder":"0","scrolling":"no","marginheight":"0","marginwidth":"0"}})])]),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticClass:"mt-4",attrs:{"target":"_blank","large":"","dark":"","block":"","elevation":"","color":"grey darken-1","href":"https://www.google.com/maps/place/Gen.+Karola+Kniaziewicza+20,+91-347+%C5%81%C3%B3d%C5%BA/@51.7995328,19.4350245,17z/data=!3m1!4b1!4m6!3m5!1s0x471bca91bef590e3:0x5da9fe20483badce!8m2!3d51.7995328!4d19.4372185!16s%2Fg%2F11c3q3t040"}},[_vm._v(" Przejdź do mapy! ")]):_vm._e()],1),_c('v-col',{staticClass:"d-flex align-center",class:{
          'px-0': _vm.$vuetify.breakpoint.mdAndDown,
          'pl-10': _vm.$vuetify.breakpoint.lgAndUp,
        },attrs:{"xl":"6","md":"12"}},[_c('div',{class:{
            'px-0': _vm.$vuetify.breakpoint.lgAndUp,
            'px-6 d-flex flex-column align-center': _vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp,
            'px-4 d-flex flex-column align-center': _vm.$vuetify.breakpoint.smAndDown,
          }},[_c('h2',{staticClass:"w-4",class:{
              'mb-6': _vm.$vuetify.breakpoint.lgAndUp,
              'mb-0': _vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp,
            }},[_vm._v(" "+_vm._s(_vm.posts.title)+" ")]),_c('v-divider',{staticClass:"divider",style:(_vm.dividerWidth)}),_c('p',{staticClass:"p2",class:_vm.$vuetify.breakpoint.smAndDown && 'mb-0',style:(_vm.lgTextAlign)},[_vm._v(" "+_vm._s(_vm.posts.excerpt)+" ")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"target":"_blank","large":"","dark":"","block":_vm.$vuetify.breakpoint.mdAndDown,"elevation":"","color":"grey darken-1","href":"https://www.google.com/maps/place/Gen.+Karola+Kniaziewicza+20,+91-347+%C5%81%C3%B3d%C5%BA/@51.7995328,19.4350245,17z/data=!3m1!4b1!4m6!3m5!1s0x471bca91bef590e3:0x5da9fe20483badce!8m2!3d51.7995328!4d19.4372185!16s%2Fg%2F11c3q3t040"}},[_vm._v(" Przejdź do mapy! ")]):_vm._e()],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }