<template>
  <v-card
    :class="{
      'pa-8': $vuetify.breakpoint.smAndUp,
      'pa-4': $vuetify.breakpoint.xsOnly,
    }"
    class="desc-shadow"
  >
    <v-row>
      <v-col
        :class="{
          'pb-0': $vuetify.breakpoint.smAndUp,
          'px-3 pb-4': $vuetify.breakpoint.xsOnly,
        }"
        cols="12"
      >
        <span
          class="h2 w-4"
          :class="{
            'h2': $vuetify.breakpoint.smAndUp,
            'h4': $vuetify.breakpoint.xsOnly,
          }"
        >{{ parametersTitle }}</span>
      </v-col>
      <v-col
        cols="12"
      >
        <apartments-parameters
          v-if="apartments === 'basic'"
          :parameters="descriptionBasic.params"
        />
        <apartments-parameters
          v-else-if="apartments === 'lux'"
          :parameters="descriptionLux.params"
        />
      </v-col>
    </v-row>
    
    <v-row class="pt-8">
      <v-col
        :class="{
          'pb-0': $vuetify.breakpoint.smAndUp,
          'px-4 pb-0': $vuetify.breakpoint.xsOnly
        }"
        cols="12"
      >
        <span class="h2 w-4">{{ priceTitle }}</span>
      </v-col>
      <v-col
        md="6"
        cols="12"
      >
        <apartments-prices
          v-if="apartments === 'basic'"
          :prices="descriptionBasic.price"
        />
        <apartments-prices
          v-else-if="apartments === 'lux'"
          :prices="descriptionLux.price"
        />
      </v-col>
    </v-row>
    <v-row class="pt-8 pl-2">
      <apartments-informations
        v-if="apartments === 'basic'"
        :additional-info="descriptionBasic.additionalInfo"
      />
      <apartments-informations
        v-else-if="apartments === 'lux'"
        :additional-info="descriptionLux.additionalInfo"
      />
    </v-row>
  </v-card>
</template>

<script>
import ApartmentsParameters from '@/components/gallery/description/ApartmentsParameters.vue';
import ApartmentsPrices from '@/components/gallery/description/ApartmentsPrices.vue';
import ApartmentsInformations from '@/components/gallery/description/ApartmentsInformations.vue';

export default {
  name: 'ApartmentsDescription',
  components: {
    ApartmentsParameters,
    ApartmentsPrices,
    ApartmentsInformations
  },
  props: {
    descriptionBasic: {
      type: Object,
      default: undefined
    },
    descriptionLux: {
      type: Object,
      default: undefined
    },
    apartments: {
      type: String,
      default: 'basic',
    }
  },
  computed: {
    parametersTitle() {
      let title = '';
      if(this.descriptionBasic && this.apartments === 'basic') {
        title = this.descriptionBasic.titles[0];
      } else if (this.descriptionLux && this.apartments === 'lux') {
        title = this.descriptionLux.titles[0];
      }
      return title;
    },
    priceTitle() {
      let title = '';
      if(this.descriptionBasic && this.apartments === 'basic') {
        title = this.descriptionBasic.titles[1];
      } else if (this.descriptionLux && this.apartments === 'lux') {
        title = this.descriptionLux.titles[1];
      }
      return title;
    }
  },
};
</script>

<style lang="scss" scoped>
.desc-shadow {
  box-shadow: 0 12px 17px 2px rgb(130 49 137 / 4%), 0 5px 22px 4px rgb(130 49 137 / 4%), 0 7px 8px rgb(0 0 0 / 5%) !important; 
  border-radius: 12px;
}
</style>

