var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-8 pt-16 container-bg",class:{
    'pb-16': _vm.$vuetify.breakpoint.lgAndUp,
    'px-16': _vm.$vuetify.breakpoint.smAndUp,
    'pb-8': _vm.$vuetify.breakpoint.mdAndDown && !_vm.$vuetify.breakpoint.xsOnly,
    'px-4': _vm.$vuetify.breakpoint.xsOnly,
  }},[_c('screen-loader',{attrs:{"active":_vm.isLoading}},[_c('banner',{attrs:{"title":_vm.posts.title,"subtitle":_vm.posts.excerpt,"image":_vm.contactBannerImage}})],1),_c('v-row',{class:{
      'py-16': _vm.$vuetify.breakpoint.lgAndUp,
      'pt-16 pb-0': _vm.$vuetify.breakpoint.mdAndDown && !_vm.$vuetify.breakpoint.xsOnly,
      'pt-6': _vm.$vuetify.breakpoint.xsOnly,
    }},[_c('screen-loader',{attrs:{"active":_vm.isLoading}},[_c('v-col',{attrs:{"offset-lg":"1","lg":"10","md":"12","sm":"12","cols":"12"}},[_c('span',{staticClass:"w-3 h2"},[_vm._v(_vm._s(_vm.policyTitle))]),_c('div',{staticClass:"text-wrapper"},_vm._l((_vm.posts.info),function(p,index){return _c('p',{key:index,staticClass:"mt-8 w-2 p2"},[_vm._v(" "+_vm._s(index > 0 ? p.children.text : '')+" ")])}),0)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }