<template>
  <section
    :class="{
      'pt-16 px-16': $vuetify.breakpoint.lgAndUp,
      'pt-8 px-0': $vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.lgAndUp,
    }"
  >
    <span :class="titleClass">{{ contactTitle }}</span>
    <v-row :class="wrapperPadding">
      <contact-section
        :border-styles="columnBorder"
        :icon="require('@/assets/icons/ico_phone.svg')"
        :information="contactPhone"
      />
      <contact-section
        :border-styles="borderRight"
        :icon="require('@/assets/icons/ico_email.svg')"
        :information="contactEmail"
      />
      <contact-section
        :border-styles="localizationBorder"
        :icon="require('@/assets/icons/ico_location-big.svg')"
        :information="contactAddress"
      />
      <v-col
        v-if="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.xsOnly"
        sm="6"
        cols="12"
        :class="{
          'pl-4': !$vuetify.breakpoint.xsOnly
        }"
        :style="borderTop"
      >
        <div
          class="booking"
          :class="{
            'd-flex flex-column align-center': $vuetify.breakpoint.xsOnly
          }"
        >
          <v-img
            width="80px"
            class="mb-6"
            src="@/assets/icons/ico_booking-black.svg"
          />
          <div :class="contactInfoClass">
            <span class="h3 w-3">Zarezerwuj teraz!</span><br>
            <v-btn
              class="mt-6 p1 w-3"
              text
              large
              outlined
              color="#1d1d4e"
              to="/"
            >
              Przejdź do Booking.com
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import ContactSection from './sections/ContactSection.vue';

export default {
  name: 'ContactInformation',
  components: {
    ContactSection,
  },
  props: {
    contactInfo: {
      type: Array,
      default: undefined,
    }
  },
  computed: {
    contactTitle() {
      return this.contactInfo[0] ? this.contactInfo[0].mainTitle.title : 'Zadzwoń do nas, napisz email, lub przyjdź!';
    },
    contactPhone() {
      return this.contactInfo[1] ? this.contactInfo[1].phoneInfo : {};
    },
    contactEmail() {
      return this.contactInfo[2] ? this.contactInfo[2].emailInfo : {};
    },
    contactAddress() {
      return this.contactInfo[3] ? this.contactInfo[3].addressInfo : {};
    },
    contactInfoClass() {
      return !this.$vuetify.breakpoint.xsOnly ? 'text' : 'text-xs';
    },
    titleClass() {
      return this.$vuetify.breakpoint.xsOnly ? 'h3 w-2' : 'h2';
    },
    wrapperPadding() {
      return this.$vuetify.breakpoint.xsOnly ? 'pt-8' : 'pt-16';
    },
    columnBorder() {
      return `border-right: ${this.$vuetify.breakpoint.smAndUp ? '2px dotted' : ''};`;
    },
    borderRight() {
      return `border-right: ${this.$vuetify.breakpoint.smAndUp && !this.$vuetify.breakpoint.lgAndUp || this.$vuetify.breakpoint.xsOnly ? '' : '2px dotted'};`;
    },
    localizationBorder() {
      return `border-right: ${this.$vuetify.breakpoint.smAndUp && !this.$vuetify.breakpoint.lgAndUp ? '2px dotted' : ''}; border-top: ${this.$vuetify.breakpoint.smAndUp && !this.$vuetify.breakpoint.lgAndUp ? '2px dotted' : ''};`;
    },
    borderTop() {
      return `border-top: ${this.$vuetify.breakpoint.xsOnly ? '' : '2px dotted'};`;
    }
  },
};
</script>

<style lang="scss" scoped>
.text {
    padding-left: 16px;
}

.text-xs {
  padding-left: 0px;
  text-align: center;
}
</style>